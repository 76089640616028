.preview-modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-modal-content {
  background-color: #fff;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  width: 80%;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  font-family: 'Arial', sans-serif;
  position: relative;
}

.close-button {
  color: #aaa;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  font-family: "Rubik";
}

.close-button:hover,
.close-button:focus {
  color: black;
  text-decoration: none;
}

.modal-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  font-family: "Rubik";
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.preview-section {
  margin-bottom: 20px;
}

.section-title {
  font-size: 18px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  margin-bottom: 10px;
  font-family: "Rubik";
}

.section-content p {
  margin: 5px 0;
  font-size: 14px;
  font-family: "Rubik";
}

.preview-logo {
  width: 80px;
  height: auto;
  display: block;
  margin: 10px 0;
}

.submit-button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 14px;
  margin: 20px auto 0;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  font-family: "Rubik";
}

.submit-button:hover {
  background-color: #45a049;
}
