.success-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
  }
  
  .success-icon {
    font-size: 4rem;
    color: green;
    animation: success 1.5s ease-in-out infinite;
    font-family: "Rubik";
  }
  
  @keyframes success {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  