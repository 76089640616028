.bulk-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.file-upload{
    width: 266px;
    height: 450px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    border: #19172C 1px solid;
}
.csv-file-input{
    font-size: 12px;
    font-family: "Rubik";
    color: black;
    background-color: #f2f2f2;
    border-radius: 5px;
    padding: 2px;
    width: 200px;
    /* margin-left: 100px; */
    /* margin-top: 25px; */
}
.status-box{
    font-size: 12px;
    font-family: "Rubik";
    color: gray;
}
.status-bar{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.stick{
    width: 100px;
    height: 1.5px;
    background-color:gray
}
.status-bar-box{
    width: 200px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-family: "Rubik";
    color: white;
    background-color: #19172C;
    border-radius: 5px;
    cursor: pointer;
}