.progress-bar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  /* width: 80%; */
}

.step-container {
  display: flex;
  align-items: center;
  position: relative;
  /* width: 100%; */
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #19172C;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-family: "Rubik";
  border: 1.5px solid gray;
  /* Add a border */
  color: white;
}

.circle.active {
  background-color: #4caf50;
  /* Active step color */
  color: white;
}

.label {
  text-align: center;
  font-size: 10px;
  font-family: 'Rubik';
}

.label.active {
  font-weight: bold;
  color: #4caf50;
  /* Active label color */
}

.line {
  height: 1.5px;
  background-color: gray;
  width: 60px;
}

.line.active {
  background-color: #4caf50;
  /* Active line color */
}