.main-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff; /* White background color */
    padding: 10px 15px; /* Adjust padding as needed */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow */
    height: 45px;
    border-radius: 5px;    
  }
  
  .search-box {
    height: 30px;
    width: 300px;
    border-radius: 10px;
    border: 0.5px;
    padding-left: 15px;
    outline: none;
    background-color: #f2f2f2;
  }
  
  
  .header-title {
    color: #000;
    font-family: 'Rubik';
    font-size: 14px;
    margin-top: 20px;
    font-weight: 600;
  }
  
  .user-profile {
    /* Add styles for the user profile container */
  }

  .button {
    height: 32px;
    background-color:  #FF5733 ;
    display: flex;
    align-items: center;
    justify-content:space-between;
    outline: none;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
    cursor:pointer;
    /* box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5); */
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 5px;
  }

  .button-text {
    font-size: 13px;
    color: #fff;
    margin-top: 15px;
    font-family: 'Rubik';
    margin-right: 2px;
  }

  .tab {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #f2f2f2;
    padding: 5px;
    box-shadow: 5px;
  }
  