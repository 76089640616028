/* AccessPolicyForm.css */

.form-container {
    height: calc(100vh - 80px);
    width: 100%;
  }
  
  .form-row {
    width: 100%;
    height: 85px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    font-family: Rubik, sans-serif;
    padding-left: 10px;
    justify-content: space-between;
    padding-right: 10px;
    margin-top: 5px;
    background-color: #f2f2f2;
    padding-top: 10px;
  }
  
  .form-column {
    display: flex;
    flex-direction: column;
    width: 45%;
  }
  
  .input-field {
    width: 100%;
    height: 20px;
    background-color: #f2f2f2;
    border: none;
    outline: none;
    border-radius: 5px;
    padding-left: 5px;
    margin-top: 5px;
  }
  
  .error-message {
    color: red;
    font-size: 10px;
    font-family: "Rubik";
    /* height: 15px; */
  }
  
  .form-section {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 10px;
    font-size: 12px;
    font-weight: 600;
    font-family: Rubik, sans-serif;
    margin-top: 5px;
    background-color: #f2f2f2;
  }
  
  .checkbox-container {
    font-size: 12px;
    font-weight: 500;
    color: gray;
    margin-top: 0%;
    font-family: "Rubik";
  }
  
  .radio-row {
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .access-section {
    width: 100%;
    font-size: 12px;
    font-weight: 600;
    font-family: Rubik, sans-serif;
    padding: 10px;
    padding-top: 15px;
    background-color: #f2f2f2;
    margin-top: 5px;
  }
  