/* recordList.css */

.bottom-sheet {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 90%;
  background-color: white;
  z-index: 1000;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  flex-direction: column;
}

.sheet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 15px 20px; */
  gap: 30px;
  background-color: #f2f2f2;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.sheet-title {
  margin: 0;
  font-size: 13px;
  font-family: "Rubik";
  /* padding-left: 15px;
  padding-right: 15px; */
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sheet-content {
  padding: 20px;
  overflow-y: auto;
  flex-grow: 1;
  color: black;
}

.sheet-record-list {
  width: 100%;
  height: 80%;
}

.description-details {
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  font-family: "Rubik";
  font-weight: 600;
}

.pagination-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  height: 50px;
  padding-top: 20px;
}