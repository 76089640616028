.list-container {
    overflow-y: auto;
    /* Allows vertical scrolling */
    overflow-x: hidden;
    height: 400px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: black;
    font-family: "Rubik";
    flex-direction: column;
    /* Hide horizontal scrollbar if not needed */
}

.single-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: #fff;
}

.title-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title-text {
    font-family: "Rubik";
    font-size: 10px;
    color: gray;
}

.item-box {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 5px;
}

.status-box-history {
    width: 95px;
    height: 40px;
    border-radius: 5px;
    background-color: white;
    font-size: 10px;
    font-family: "Rubik";
    color: gray;
    align-items: center;
    /* justify-content: center; */
    display: flex;
    flex-direction: column;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
    /* justify-content: center; */
}

.status-count {
    font-size: 10px;
    font-family: "Rubik";
    color: black;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 70%;
}

.box-sub-header {
    width: 100%;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-family: "Rubik";
    color: white;
    font-weight: 500;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.file-download-box {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 6px;
    /* flex-direction: column; */
    padding: 5px;
    background-color: #19172C;
    box-shadow: 3px;
    border-radius: 5px;
    color: white;

}

.slBox {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    font-family: "Rubik";
    color: black;
    border-right: 2px solid #f2f2f2;
    border-bottom: 2px solid #f2f2f2;
    text-align: center;
}

.slBoxMain {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    font-family: "Rubik";
    color: black;
    border-right: 2px solid #f2f2f2;
    border-bottom: 2px solid #f2f2f2;
    text-align: center;
}

.filter-container {
    background-color: white;
    height: 35px;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.filter-button {
    width: 100px;
    height: 75%;
    background-color: #19172C;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}