.tenet-header {
    width: 100%;
    padding: 5px;
    padding-left: 10px;
    font-size: 14px;
    font-weight: 600;
    color: black;
    font-family: 'Rubik';
   
}
.main-container-tenet {
    padding: 5px;
    display: "flex";
    align-items: center;
    justify-content: space-evenly;
    flex-direction: "row";
    width: 100%;
}