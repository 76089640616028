/* BrandingForm.css */

.branding-container {
  height: calc(100vh - 80px);
  width: 100%;
}

.branding-input-wrapper {
  width: 40%;
  display: flex;
  flex-direction: column;
  font-family: "Rubik";
}

.branding-input-wrapper input {
  width: 100%;
  height: 40px;
  background-color: #f2f2f2;
  border: none;
  outline: none;
  border-radius: 5px;
  padding-left: 5px;
  margin-top: 5px;
}

.branding-textarea {
  width: 100%;
  border: none;
  outline: none;
  background-color: #f2f2f2;
  height: 130px;
  border-radius: 5px;
  padding: 5px;
}

.branding-radio-wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  font-size: 12px;
  font-weight: 600;
  font-family: "Rubik";
}

.branding-radio-input {
  margin-top: 5px;
}

.branding-submit-button {
  width: 120px;
  height: 40px;
  background-color: #FF5733;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: white;
  font-family: "Rubik";
}

.branding-submit-button:hover {
  cursor: pointer;
}

.branding-error-message {
  color: red;
  font-size: 10px;
  height: 15px;
  font-family: "Rubik";
}

.logo-input{
  font-size: 14px;
  font-family: "Rubik";
}
